.hero-row-mobile-custom{
    display: flex;
    margin-top: -30px;
}

.hero-row-mobile-custom .col1{
    flex: 3;
    padding-left: 10px;
}

.hero-row-mobile-custom .col2{
    flex: 1;
}

.hero-row-mobile-custom .col2 button{
    width: 100%;
    padding: 11px 0px;
    font-size: 12px;
}

.logo-partner img{
    filter: grayscale(100%);
    opacity: 0.7;
    transition: filter 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.logo-partner img:hover {
    filter: grayscale(0%);
    opacity: 1;
}

@media (min-width: 768px) {
    .hero-row-mobile-custom {
        display: flex;
        align-items: flex-start;
        width: 400px;
    }
    .hero-row-mobile-custom .col1{
        padding-left: 0px;
    }
}
